import { PageId } from 'bcn/core/models/app-models'
import {
  LinePageMetadata, LinePageMetadataOptions,
  PageMetadata, StopPageMetadata, StopPageMetadataOptions,
} from 'common/core/models/page-metadata-models'
import { StopTypeNumCode, TransportTypeNumCode } from '../../stops/models/stop-models'

const stopPageMetadata: {[key in StopTypeNumCode]: StopPageMetadata} = {
  [StopTypeNumCode.Bus]: {
    title: ({ stopCode }) => `Parada de buses ${stopCode} | Cuánto tarda mi bus`,
    description: ({ stopCode }) => (
      `Consulta cuánto tiempo le queda a tu bus para llegar a la parada ${stopCode}`
    ),
  },
  [StopTypeNumCode.Metro]: {
    title: ({ stopCode }) => `Parada de Metro ${stopCode} | Tiempo espera Metro`,
    description: ({ stopCode }) => (
      `Consulta cuándo llega el siguiente tren de Metro en la parada ${stopCode}`
    ),
  },
}

const linePageMetadata: {[key in TransportTypeNumCode]: LinePageMetadata} = {
  [TransportTypeNumCode.Bus]: {
    title: ({ lineNumber, lineName }) => `Horarios bus ${lineNumber} | ${lineName}`,
    description: ({ lineNumber, lineName }) => (
      `Consulta los horarios y las paradas de la línea de bus ${lineNumber}: ${lineName}`
    ),
  },
  [TransportTypeNumCode.Metro]: {
    title: ({ lineNumber, lineName }) => `Horarios de la línea de Metro ${lineNumber} | ${lineName}`,
    description: ({ lineNumber, lineName }) => (
      `Consulta los horarios y las paradas de la línea de Metro ${lineNumber}: ${lineName}`
    ),
  },
}

export const pageMetadataCollection: {[key in PageId]: PageMetadata} = {
  [PageId.HomePage]: {
    title: () => (
      'OK Transporte Barcelona | Cuánto tarda mi bus o tren'
    ),
    description: () => (
      'Consulta cuánto tiempo le queda a tu bus o tren para llegar a la parada.'
    ),
  },
  [PageId.StopPage]: {
    title: (options: StopPageMetadataOptions) => (
      stopPageMetadata[options.stopTypeCode as StopTypeNumCode].title(options)
    ),
    description: (options: StopPageMetadataOptions) => (
      stopPageMetadata[options.stopTypeCode as StopTypeNumCode].description(options)
    ),
  },
  [PageId.LinesPage]: {
    title: () => (
      'Líneas de buses de Barcelona'
    ),
    description: () => (
      'Consulta paradas y horarios de las líneas de buses de Barcelona.'
    ),
  },
  [PageId.LinePage]: {
    title: (options: LinePageMetadataOptions) => (
      linePageMetadata[options.transportTypeCode as TransportTypeNumCode].title(options)
    ),
    description: (options: LinePageMetadataOptions) => (
      linePageMetadata[options.transportTypeCode as TransportTypeNumCode].description(options)
    ),
  },
  [PageId.SearchPage]: {
    title: () => (
      'Buscar parada | OK Transporte Barcelona'
    ),
    description: () => (
      `Encuentra tu parada por su nombre o número y consulta cuándo tarda tu bus en llegar.
      Tiempo de espera de todos los buses de Barcelona`
    ),
  },
  [PageId.StopMapPage]: {
    title: () => (
      'Mapa de paradas | OK Transporte Barcelona'
    ),
    description: () => (
      `Encuentra tu parada en el mapa y consulta cuánto le queda a tu bus para llegar.
      Tiempo de espera de todos los buses de Barcelona`
    ),
  },

  [PageId.PostersPage]: {
    title: () => (
      'Plano y mapas de transporte de Barcelona'
    ),
    description: () => (
      'Planos y mapas actualizados del transporte de Barcelona en alta definición.'
    ),
  },

  [PageId.BusPosterPage]: {
    title: () => (
      'Plano de buses de Barcelona actualizado'
    ),
    description: () => (
      'Consulta el plano de buses de Barcelona actualizado en alta definición.'
    ),
  },
  [PageId.NocturnalBusPosterPage]: {
    title: () => (
      'Plano de buses nocturnos de Barcelona actualizado'
    ),
    description: () => (
      'Consulta el plano de buses nocturnos de Barcelona actualizado en alta definición.'
    ),
  },
  [PageId.NewNetworkBusPosterPage]: {
    title: () => (
      'Plano actualizado de la nueva red de buses de Barcelona'
    ),
    description: () => (
      'Consulta el plano actualizado de la nueva red de buses de Barcelona en alta definición.'
    ),
  },
  [PageId.MetroPosterPage]: {
    title: () => (
      'Plano de Metro de Barcelona actualizado'
    ),
    description: () => (
      'Consulta el plano de Metro de Barcelona actualizado en alta definición.'
    ),
  },
  [PageId.RodaliesPosterPage]: {
    title: () => (
      'Plano de Rodalíes de Barcelona actualizado'
    ),
    description: () => (
      'Consulta el plano de Rodalíes de Barcelona actualizado en alta definición.'
    ),
  },
  [PageId.TrainsPosterPage]: {
    title: () => (
      'Plano de la red ferroviaria de Barcelona actualizado'
    ),
    description: () => (
      'Consulta el plano de la red ferroviaria de Barcelona actualizado en alta definición.'
    ),
  },
}
