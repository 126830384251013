import busStopIcon from 'bcn/maps/images/stop_bus.svg'
import busNocturnalStopIcon from 'bcn/maps/images/stop_bus_nocturnal.svg'
import metroStopIcon from 'bcn/maps/images/stop_metro.svg'
import { StopTypeNumCode } from './stops/models/stop-models'

interface BcnStopIcons {
  normal: {
    [stopTypeCode in StopTypeNumCode]: string
  },
  nocturnal: {
    [stopTypeCode: string]: string
  }
}

export const stopMapIcons: BcnStopIcons = {
  normal: {
    [StopTypeNumCode.Bus]: busStopIcon,
    [StopTypeNumCode.Metro]: metroStopIcon,
  },
  nocturnal: {
    [StopTypeNumCode.Bus]: busNocturnalStopIcon,
  },
}
