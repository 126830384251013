export const commonMessagesInCatalonian = {
  settings_language: 'Idioma',

  ads_advertisement_title: 'Publicitat',
  ads_hide: 'Amagar',

  accept_button: 'Acceptar',

  stops_favs_title: 'Parades guardades',
  stops_no_favorites: `No tens parades guardades.
    Quan consultis una parada, prem el botó "Desar parada" perquè aparegui aquí.`,
  stop_favs_error: 'Failed to retrieve saved stops. Please, try again.',
  stops_favs_sort_start: 'Ordenar',
  stops_favs_sort_finish: 'Finalitzar',

  chat_card_secondary: 'Necessites ajuda?',
  chat_card_primary: 'ESCRIU-NOS PER XAT',

  share_app_modal_title: 'Compartir l\'aplicació',
  share_app_modal_text_one: 'Envia aquest enllaç als teus amics:',
  share_app_modal_copy_clipboard: 'Copiar al porta-retalls',
  share_app_modal_whatsapp: 'Enviar per WhatsApp',
  share_app_modal_telegram: 'Enviar per Telegram',

  install_card_secondary_one: 'La nostra app és més còmoda ',
  install_card_secondary_two: 'd\'usar i pesa molt poc',
  install_card_primary: 'INSTAL·LAR GRATIS',

  rate_app_card_secondary: 'Ajuda\'ns a millorar',
  rate_app_card_primary: 'VALORA L\'APP',

  sync_reminder_secondary: 'Les teves parades al núvol',
  sync_reminder_primary: 'INICIAR SESSIÓ',

  action_bar_install_app: 'Instal·lar app',
  modal_close_btn: 'Tancar',

  no_internet_error: 'Sembla que no hi ha connexió a internet',

  search_tab_panel_by_text: 'Per codi, nom o línia',
  search_tab_panel_on_map: 'En el mapa',

  stop_search_action_bar_title: 'Cercar parada',
  stop_search_loading: 'Carregant les parades',
  stop_search_error: 'S\'he produït un error en obtenir el llistat de parades. Si us plau, intenta-ho de nou.',
  stop_search_by_code: 'Consultar parada per número',
  search_found_stops_title: 'Parades trobades',
  search_found_lines_title: 'Línies trobades',
  line_search_show_more: 'MOSTRAR {numberOfItems} LÍNIES MÉS',
  search_show_more_stops: 'MOSTRAR {numberOfItems} PARADES MÉS',
  search_stops_not_found: 'No s\'han trobat parades amb la consulta "{searchQuery}"',
  search_stop_by_unknown_code: 'Consultar parada amb codi {stopCode}',

  stop_map_action_bar_title: 'Cercar parada',
  stop_map_loading: 'Carregant el mapa',
  stop_map_error: 'S\'he produït un error en obtenir les dades del mapa. Si us plau, intenta-ho de nou.',
  map_search_places_field_placeholder: 'Cercar llocs en el mapa',
  stop_map_infowindow_lines: 'Línies: ',
  stop_map_infowindow_go_to_stop: 'Consultar parada',

  stop_action_bar_title: 'Parada',
  stop_action_bar_search_link_title: 'Cercar parada',
  stop_refresh_button: 'Actualitzar',
  stop_loading: 'Carregant la parada',
  stop_error: 'S\'ha produït un error en obtenir les dades de la parada. Si us plau, intenta-ho de nou.',
  stop_fav_arrivals_hint: 'CONSELL: Perquè una línia aparegui sempre amunt, usa el seu botó "Fixar línia a dalt"',
  stop_error_arrivals_sources_btn: 'Per què no hi ha dades?',
  stop_actions_remove_fav: 'Guardada',
  stop_actions_add_fav: 'Guardar parada',
  stop_actions_rename: 'Canviar de nom',
  stop_actions_show_on_map: 'Veure en mapa',
  stop_departure_direction: 'A',
  stop_departure_platform: 'Via {platform}',
  stop_departure_distance: '{distance} m',
  stop_departure_no_data: 'actualment sense dades',
  stop_departure_actions_fav_arrival_add: 'Fixar línia a dalt',
  stop_departure_actions_fav_arrival_remove: 'No fixar línia a dalt',
  stop_departure_actions_go_to_line: 'Consultar línia',
  stop_departure_actions_show_on_map: 'Veure línia en el mapa',
  arrivals_sources_modal_title: 'Com obtenim les dades',
  arrivals_sources_modal_ok: 'Acceptar',
  stop_fav_modal_title: 'Guardar parada',
  stop_fav_modal_select_name: 'Tria un nom:',
  stop_fav_modal_cancel: 'Cancel·lar',
  stop_fav_modal_save: 'Guardar',
  stop_fav_rename_modal_title: 'Canviar de nom',
  stop_fav_rename_modal_select_name: 'Tria un nom nou:',
  stop_fav_rename_modal_cancel: 'Cancel·lar',
  stop_fav_rename_modal_save: 'Guardar',
  stop_remove_fav_modal_title: 'Eliminar parada guardada',
  stop_remove_fav_modal_text: 'Eliminar aquesta parada de les parades guardades?',
  stop_remove_fav_cancel: 'Cancel·lar',
  stop_remove_fav_ok: 'Eliminar',

  line_search_action_bar_title: 'Línies',
  line_search_loading: 'Carregant les línies',
  line_search_error: 'S\'he produït un error en recuperar el llistat de línies. Si us plau, intenta-ho de nou.',
  line_search_field_placeholder: 'Cercar línia per número o nom',

  line_action_bar_title: 'Línia',
  line_action_bar_search_link_title: 'Cercar línia',
  line_loading: 'Carregant la línia',
  line_error: 'S\'ha produït un error en obtenir les dades de la línia. Si us plau, intenta-ho de nou.',
  line_show_on_map_btn: 'Veure línia en el mapa',
  line_schedule_btn: 'Horari a {destination}',
  line_stops_title: 'Parades de la línia',
  line_schedule_modal_title: 'Horari',

  posters_action_bar_title: 'Plànols i mapes',
  posters_available_offline: 'Disponible sense internet',
  posters_not_available_offline: 'Necessita connexió a internet',

  settings_action_bar_title: 'Configuració',
  settings_theme: 'Tema visual',
  settings_theme_use_system: 'Segons el tema del sistema',
  settings_theme_light: 'Tema clar',
  settings_theme_dark: 'Tema fosc',
  settings_more_stuff: 'Més coses',
  secondary_actions_rate_app: 'Valora l\'app',
  secondary_actions_use_chat: 'Escriu-nos per xat',
  secondary_actions_share_app: 'Comparteix l\'app',

  retry: 'Reintentar',

  auth_user_account: 'Compte d\'usuari',
  auth_logged_out_intro: 'Inicia sessió per guardar a la núvol les teves parades favorites. Així no els perdràs i canvis de mòbil.',
  auth_login_with_google: 'Inicia sessió amb Google',
  auth_logged_in_with: 'Sesió iniciada amb: ',
  auth_logged_in_description: 'Les vostres parades favorites es guarden en la nube de forma segura.',
  auth_logout: 'Cerrar sessió',
  auth_logout_modal_title: 'Cerrar sessió',
  auth_logout_modal_text: 'Segur que vols tancar sessió?',
  auth_logout_modal_accept: 'Cerrar sessió',
  auth_logout_modal_cancel: 'Cancel·lar',

  sync_title: 'Sincronitzar preferits',
  sync_intro: 'Tens parades desades tant al teu compte d\'usuari com a aquest dispositiu. Què vols fer amb les parades del dispositiu?',
  sync_btn_upload_local_stop_favs: 'Afegir les parades del dispositiu al meu compte d\'usuari',
  sync_btn_delete_local_stop_favs: 'Eliminar les parades del dispositiu i utilitzar només les del meu compte d\'usuari',
}
