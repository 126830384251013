import { TransportTypeNumCode } from 'bcn/stops/models/stop-models'
import { LineTheme, LineThemeBuilder, LineThemeParams } from 'common/lines/models/line-style-models'

const busThemes: {[key: string]: LineTheme} = {
  busRegular: {
    primary: '#e63c4d',
  },
  busD: {
    primary: '#894796',
  },
  busH: {
    primary: '#3c81e8',
  },
  busV: {
    primary: '#35a000',
  },
  busGray: {
    primary: '#e6e6e6',
    secondary: '#ef0000',
  },
  busNocturnal: {
    primary: '#4160a9',
  },
  busYellow: {
    primary: '#ffe046',
    secondary: '#333333',
  },
}

const metroThemes: {[lineNumber: string]: LineTheme} = {
  L1: {
    primary: '#CB2508',
  },
  L2: {
    primary: '#90278E',
  },
  L3: {
    primary: '#067634',
  },
  L4: {
    primary: '#FFC10E',
    secondary: 'black',
  },
  L5: {
    primary: '#006B9D',
  },
  L9N: {
    primary: '#DF8D33',
    secondary: 'black',
  },
  L9S: {
    primary: '#DF8D33',
    secondary: 'black',
  },
  L10N: {
    primary: '#31A8E0',
    secondary: 'black',
  },
  L10S: {
    primary: '#31A8E0',
    secondary: 'black',
  },
  L11: {
    primary: '#9ED84C',
    secondary: 'black',
  },
  LFM: {
    primary: '#0C7557',
  },
}

const defaultTheme: LineTheme = {
  primary: 'gray',
}

const grayLines = ['80', '81', '82', '83']
const redLines = ['BCTE', 'BCTO']
const yellowLines = ['86', '87', '88', '88B']

const buildBusLineTheme = ({ transportTypeCode, lineNumber }: LineThemeParams): LineTheme => {
  if (transportTypeCode === TransportTypeNumCode.Bus) {
    if (yellowLines.includes(lineNumber) || lineNumber.startsWith('VB')) {
      return busThemes.busYellow
    }
    if (lineNumber.startsWith('D')) {
      return busThemes.busD
    }
    if (lineNumber.startsWith('H')) {
      return busThemes.busH
    }
    if (lineNumber.startsWith('V')) {
      return busThemes.busV
    }
    if (grayLines.includes(lineNumber)) {
      return busThemes.busGray
    }
    if (lineNumber.match(/^\d+$/) || redLines.includes(lineNumber)) {
      return busThemes.busRegular
    }
    if (lineNumber.startsWith('N')) {
      return busThemes.busNocturnal
    }
    return busThemes.busYellow
  }
  return busThemes.busYellow
}

const buildMetroLineTheme = ({ lineNumber }: LineThemeParams) => {
  const foundTheme = metroThemes[lineNumber]
  return foundTheme || defaultTheme
}

const buildThemeFnMapping: {
  [type in TransportTypeNumCode]: LineThemeBuilder
} = {
  [TransportTypeNumCode.Bus]: buildBusLineTheme,
  [TransportTypeNumCode.Metro]: buildMetroLineTheme,
}

export const buildLineTheme: LineThemeBuilder = (params) => {
  const foundBuilder = buildThemeFnMapping[params.transportTypeCode as TransportTypeNumCode]
  if (foundBuilder) {
    return foundBuilder(params)
  }
  return defaultTheme
}
