import React from 'react'
import 'pinch-zoom-element'
import s from './PosterImageView.module.scss'

interface Props {
  title: string
  imageUrl: string
}

const PosterImageView: React.FC<Props> = (props) => (
  <pinch-zoom class={s.pinchZoomContainer}>
    <img
      src={props.imageUrl}
      alt={props.title}
      className={s.zoomable}
    />
  </pinch-zoom>
)

export default PosterImageView
