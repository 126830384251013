import React from 'react'
import { Link } from 'react-router-dom'
import { StopFav } from 'common/stops/services/stop-favs-store/StopFavsStore'
import { PageId } from 'bcn/core/models/app-models'
import { ReactComponent as KeyboardIcon } from 'common/images/keyboard-24px.svg'
import { ReactComponent as MapIcon } from 'common/images/map-24px.svg'
import ActionBar from 'common/ui/components/ActionBar/ActionBar'
import { RouteManager } from 'bcn/core/services/route-manager/RouteManager'
import PageMetadata from 'common/core/components/PageMetadata/PageMetadata'
import ExternalSectionTitle from 'common/ui/components/ExternalSectionTitle/ExternalSectionTitle'
import PageLayout from 'common/ui/components/PageLayout/PageLayout'
import Container from 'common/ui/components/Container/Container'
import PopularStops from 'common/stops/components/PopularStops/PopularStops'
import InstallAppCard from 'common/ui/components/InstallAppCard/InstallAppCard'
// import FeedbackCardButton from 'common/ui/components/FeedbackCardButton/FeedbackCardButton'
import FavoritesSection from 'common/stops/components/FavoritesSection/FavoritesSection'
import { FormattedMessage, useIntl } from 'react-intl'
import SyncReminderCard from 'common/ui/components/SyncReminderCard/SyncReminderCard'
import s from './HomePageView.module.scss'

const popularStops: StopFav[] = [
  {
    name: 'Plaça Espanya - FGC',
    stopCode: '108',
  },
  {
    name: 'La Campana',
    stopCode: '868',
  },
  {
    name: 'Trinitat Nova',
    stopCode: '4-70339',
  },
  {
    name: 'Passeig De Gràcia',
    stopCode: '4-70213',
  },
]

function renderIntro() {
  const text1 = <FormattedMessage id="home_intro_one" />
  const text2 = <FormattedMessage id="home_intro_two" />
  return (
    <div className={s.sectionIntro}>
      <div className={s.smallWidthIntro}>{ text1 } { text2 }</div>
      <div className={s.normalWidthIntro}>
        <span className="globalInlineBlock">{ text1 }&nbsp;</span>
        <span className="globalInlineBlock">{ text2 }</span>
      </div>
    </div>
  )
}

const renderButtonsSection = () => (
  <>
    { renderIntro() }
    <div className={s.mainButtonsSection}>
      <Link to={RouteManager.getStopsSearchUrl()} className={s.mainButton}>
        <KeyboardIcon /><br />
        <FormattedMessage id="home_stop_search_button_one" />&nbsp;<br className={s.stopMapButtonLineBreak} />
        <FormattedMessage id="home_stop_search_button_two" />
      </Link>
      <Link to={RouteManager.getStopsMapUrl()} className={s.mainButton}>
        <MapIcon /><br />
        <FormattedMessage id="home_map_search_button_one" /><br />
        <FormattedMessage id="home_map_search_button_two" />
      </Link>
    </div>
  </>
)

const HomePageView: React.FC = () => {
  const intl = useIntl()
  return (
    <PageLayout
      actionbar={(
        <ActionBar
          title="OK Transporte Barcelona"
        />
      )}
    >
      <PageMetadata pageId={PageId.HomePage} />
      <Container>
        { renderButtonsSection() }
        <FavoritesSection />
        <SyncReminderCard className={s.syncReminderCard} />
        <ExternalSectionTitle title={intl.formatMessage({ id: 'home_popular_stops' })} />
        <PopularStops stops={popularStops} />
        {/* <FeedbackCardButton className={s.chatCard} /> */}
        <InstallAppCard className={s.installAppCard} />
      </Container>
    </PageLayout>
  )
}

export default HomePageView
