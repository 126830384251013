import { commonMessagesInCatalonian } from 'common/i18n/helpers/common-translations-ca.helpers'
import { commonMessagesInEnglish } from 'common/i18n/helpers/common-translations-en.helpers'
import { commonMessagesInSpanish } from 'common/i18n/helpers/common-translations-es.helpers'

const messagesInSpanish = {
  ...commonMessagesInSpanish,

  navbar_stops: 'Paradas',
  navbar_lines: 'Líneas',
  navbar_posters: 'Planos',
  navbar_settings: 'Ajustes',

  share_app_text: 'Prueba la app OK Transporte Barcelona',

  /* Home */
  home_intro_one: 'Selecciona una parada',
  home_intro_two: 'para consultar cuándo llega a ella tu bus o Metro',
  home_stop_search_button_one: 'Buscar parada',
  home_stop_search_button_two: 'por nº o nombre',
  home_map_search_button_one: 'Buscar parada',
  home_map_search_button_two: 'en el mapa',

  home_popular_stops: 'Paradas populares',

  // eslint-disable-next-line quotes
  stop_search_field_placeholder: `Ej. '123', 'av Catalunya' o 'bus D20'`,

  found_line_description_bus: 'Bus {lineNumber}',
  found_line_description_metro: 'Línea de Metro {lineNumber}',
  found_line_description_other: 'Línea {lineNumber}',

  line_description_bus: 'Línea de bus',
  line_description_metro: 'Línea de Metro',
  line_description_other: 'Línea de transporte',

  stop_description_bus: 'Parada de buses',
  stop_description_metro: 'Parada de Metro',
  stop_description_other: 'Parada',

  search_stop_lines_desc_bus: 'Buses',
  search_stop_lines_desc_other: 'Líneas',

  next_arrivals_title_buses: 'Próximos buses',
  next_arrivals_title_trains: 'Próximos trenes',

  posters_buses: 'Mapa de buses',
  posters_new_buses: 'Plano nueva red de buses',
  posters_nocturnal_buses: 'Mapa de buses nocturnos',
  posters_metro: 'Plano de Metro',
  posters_rodalies: 'Plano de Rodalíes',
  posters_trains: 'Plano red ferroviaria',
}

const messagesInCatalonian = {
  ...commonMessagesInCatalonian,

  navbar_stops: 'Parades',
  navbar_lines: 'Línies',
  navbar_posters: 'Plànols',
  navbar_settings: 'Config.', // 'Configuració'

  share_app_text: 'Prova l\'app OK Transporte Barcelona',

  /* Home */
  home_intro_one: 'Selecciona una parada',
  home_intro_two: 'per a consultar quan arriba a ella el teu bus o Metro',
  home_stop_search_button_one: 'Cercar parada',
  home_stop_search_button_two: 'per codi o nom',
  home_map_search_button_one: 'Cercar parada',
  home_map_search_button_two: 'en el mapa',

  home_popular_stops: 'Parades populars',

  // eslint-disable-next-line quotes
  stop_search_field_placeholder: `Ex. '123', 'av Catalunya' o 'bus D20'`,

  found_line_description_bus: 'Bus {lineNumber}',
  found_line_description_metro: 'Línia de Metro {lineNumber}',
  found_line_description_other: 'Línia {lineNumber}',

  line_description_bus: 'Línia de bus',
  line_description_metro: 'Línia de Metro',
  line_description_other: 'Línia de transport',

  stop_description_bus: 'Parada de busos',
  stop_description_metro: 'Parada de Metro',
  stop_description_other: 'Parada',

  search_stop_lines_desc_bus: 'Busos',
  search_stop_lines_desc_other: 'Línies',

  next_arrivals_title_buses: 'Pròxims busos',
  next_arrivals_title_trains: 'Pròxims trens',

  posters_buses: 'Mapa de busos',
  posters_new_buses: 'Plànol nova xarxa de bus',
  posters_nocturnal_buses: 'Mapa de busos nocturns',
  posters_metro: 'Plànol xarxa de Metro',
  posters_rodalies: 'Plànol de Rodalíes',
  posters_trains: 'Plànol xarxa ferroviària',
}

const messagesInEnglish = {
  ...commonMessagesInEnglish,

  navbar_stops: 'Stops',
  navbar_lines: 'Lines',
  navbar_posters: 'Maps',
  navbar_settings: 'Settings',

  share_app_text: 'Try the app "OK Transporte Barcelona"',

  /* Home */
  home_intro_one: 'Select a stop to check',
  home_intro_two: 'when your bus or Metro arrives there',
  home_stop_search_button_one: 'Search stop',
  home_stop_search_button_two: 'by number or name',
  home_map_search_button_one: 'Search stop',
  home_map_search_button_two: 'on the map',

  home_popular_stops: 'Popular stops',

  // eslint-disable-next-line quotes
  stop_search_field_placeholder: `E.g. '123', 'av Catalunya' oo 'bus D20'`,

  found_line_description_bus: 'Bus {lineNumber}',
  found_line_description_metro: 'Metro line {lineNumber}',
  found_line_description_other: 'Line {lineNumber}',

  line_description_bus: 'Bus line',
  line_description_metro: 'Metro line',
  line_description_other: 'Transport line',

  stop_description_bus: 'Bus stop',
  stop_description_metro: 'Metro stop',
  stop_description_other: 'Stop',

  search_stop_lines_desc_bus: 'Buses',
  search_stop_lines_desc_other: 'Lines',

  next_arrivals_title_buses: 'Next buses',
  next_arrivals_title_trains: 'Next trains',

  posters_buses: 'Bus network map',
  posters_new_buses: 'New bus network map',
  posters_nocturnal_buses: 'Night bus network map',
  posters_metro: 'Metro network map',
  posters_rodalies: 'Rodalíes network map',
  posters_trains: 'Railway network map',
}

export const bcnTranslationGroups = {
  es: messagesInSpanish,
  ca: messagesInCatalonian,
  en: messagesInEnglish,
}
