import { AppDeps } from 'bcn/core/models/app-models'
import StopMapStateStore from 'common/maps/services/stop-map-state-store/StopMapStateStore'
import FavoriteArrivalsStore from 'common/stops/services/favorite-arrivals-store/FavoriteArrivalsStore'
import SearchHistoryStore from 'common/search/services/search-history-store/SearchHistoryStore'
import VisitedStopsStore from 'common/stops/services/visited-stops-store/VisitedStopsStore'
import UiPreferencesStore from 'common/ui/services/ui-preferences-store/UiPreferencesStore'
import { UiThemeManager } from 'common/ui/services/ui-preferences-store/ui-theme-manager/UiThemeManager'
import LineApiClient from 'common/lines/services/line-api-client/LineApiClient'
import { LineListApiClient } from 'common/lines/services/line-list-api-client/LineListApiClient'
import { AppUsageTracker } from 'common/core/services/app-usage-tracker/AppUsageTracker'
import { StopListApiClient } from 'common/stops/services/stop-list-api-client/StopListApiClient'
import { BuildtimeConfig } from 'common/core/models/buildtime-config-models'
import { ParamAppConfig } from 'common/core/models/param-app-config-models'
import { AdsUsagePersistor } from 'common/ads/services/AdsUsagePersistor'
import { AdsManager } from 'common/ads/services/AdsManager'
import StopFavsStore from 'common/stops/services/stop-favs-store/StopFavsStore'
import StopApiClient from 'common/stops/services/stop-api-client/StopApiClient'
import { I18nPersistor } from 'common/i18n/services/I18nPersistor'
import { initializeApp } from 'firebase/app'
import StopFavsService from 'common/stops/services/stop-favs-service/StopFavsService'
import { CityId, UserFirestoreClient } from 'common/stops/services/user-firestore-client/UserFirestoreClient'

const firebaseConfig = {
  apiKey: 'AIzaSyALZoZ-5HIsW4sk15nkNFmgy7V56mPu9qI',
  authDomain: 'auth.oktransit.app',
  databaseURL: 'https://otm-project-23327.firebaseio.com',
  projectId: 'otm-project-23327',
  storageBucket: 'otm-project-23327.appspot.com',
  messagingSenderId: '1097746999203',
  appId: '1:1097746999203:web:7a5c738f6a754cb5f61c16',
  measurementId: 'G-HVNQ7H4341',
}
export class AppDepsBuilder {
  constructor(
    private buildtimeConfig: BuildtimeConfig,
    private paramAppConfig: ParamAppConfig,
  ) {}

  public buildAppDeps(): AppDeps {
    const uiPreferencesStore = new UiPreferencesStore('ot-bcn:uiPreferences')
    const appUsageTracker = new AppUsageTracker('ot-bcn:appUsage')
    const adsUsagePersistor = new AdsUsagePersistor('ot-bcn:ads')
    const firebaseApp = initializeApp(firebaseConfig)
    const userFirestoreClient = new UserFirestoreClient(firebaseApp, CityId.Barcelona)
    const stopFavsStore = new StopFavsStore('ot-bcn:savedStops')
    return {
      buildtimeConfig: this.buildtimeConfig,
      paramAppConfig: this.paramAppConfig,
      stopApiClient: new StopApiClient('https://api.oktransportebarcelona.com'),
      lineApiClient: new LineApiClient('https://api.oktransportebarcelona.com'),
      // stopApiClient: new StopApiClient('http://0.0.0.0:8080'),
      // lineApiClient: new LineApiClient('http://0.0.0.0:8080'),
      stopFavsStore,
      stopMapStateStore: new StopMapStateStore('ot-bcn:stopMapState'),
      favoriteArrivalsStore: new FavoriteArrivalsStore('ot-bcn:savedDepartures'),
      searchHistoryStore: new SearchHistoryStore('ot-bcn:search'),
      visitedStopsStore: new VisitedStopsStore('ot-bcn:visitedStops'),
      uiPreferencesStore,
      uiThemeManager: new UiThemeManager(uiPreferencesStore),
      // These files should be inside '/cached' dir, since
      // all files there will be cached by Nginx.
      // Also, these file names are used in index.html and sitemap generator script.
      stopListApiClient: new StopListApiClient('/cached/stop-list-20220103.json'),
      lineListApiClient: new LineListApiClient('/cached/line-list-20220103.json'),
      adsUsagePersistor,
      adsManager: new AdsManager(adsUsagePersistor, appUsageTracker, this.paramAppConfig),
      appUsageTracker,
      i18nPersistor: new I18nPersistor('ot-bcn:i18n'),
      firebaseApp,
      stopFavsService: new StopFavsService(stopFavsStore, userFirestoreClient),
      userFirestoreClient,
    }
  }
}
