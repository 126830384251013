import '@webcomponents/custom-elements'
import React from 'react'
import * as Sentry from '@sentry/react'
import ReactModal from 'react-modal'
import ReactDOM from 'react-dom'
import ScrollMemory from 'react-router-scroll-memory'
import { BrowserRouter } from 'react-router-dom'
import AppParamConfigCreator from 'common/core/services/app-param-config-creator/AppParamConfigCreator'
import AppParamConfigStore from 'common/core/services/app-param-config-store/AppParamConfigStore'
import { initSentry, configureSentry } from 'common/core/helpers/sentry-utils'
import BuildtimeConfigBuilder from 'common/core/services/buildtime-config-builder/BuildtimeConfigBuilder'
import { ClientType, ParamAppConfig } from 'common/core/models/param-app-config-models'
import { CustomDimensionName } from 'common/analytics/models/analytics-models'
import { BrandConfigContext } from 'common/core/hooks/useBrandConfigContext'
import GoogleAnalyticsManager from 'common/analytics/services/google-analytics-manager/GoogleAnalyticsManager'
import TransManager from 'common/i18n/components/TransManager'
import I18nModal from 'common/i18n/components/I18nModal/I18nModal'
import { loadAdsenseScript } from 'common/ads/helpers/adsense-helpers'
import App from 'common/core/components/App/App'
import { AppDepsBuilder } from './core/services/app-deps-builder/AppDepsBuilder'
import { AppDeps } from './core/models/app-models'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import '../common/styles/index.module.scss'
import { createBcnBrandConfig } from './brandConfigBcn'
import { bcnTranslationGroups } from './i18n/helpers/bcn-translations.helpers'
import AppRoutes from './core/components/AppRoutes'

function tryActivatePersistenStorage(appDeps: AppDeps) {
  if (navigator.storage && navigator.storage.persist) {
    navigator.storage.persist().then((granted) => {
      window.ga('send', {
        hitType: 'event',
        eventCategory: `persistentStorageActivationResult ${appDeps.paramAppConfig.clientType}`,
        eventAction: granted ? 'true' : 'false',
      })
    })
  }
}

function getAppParamConfig(): ParamAppConfig {
  const appParamConfigStore = new AppParamConfigStore('ot-bcn:paramAppConfig')
  const appParamConfigCreator = new AppParamConfigCreator(
    new URLSearchParams(window.location.search),
  )

  const appParamConfig = appParamConfigCreator.getConfig()
  const storedAppParamConfig = appParamConfigStore.getConfig()
  const defaultAppParamConfig: ParamAppConfig = {
    clientType: ClientType.Web,
    enableGA: true,
    androidClientVersionCode: undefined,
    iosClientVersion: undefined,
  }
  const finalAppParamConfig: ParamAppConfig = {
    ...defaultAppParamConfig,
    ...storedAppParamConfig,
    ...appParamConfig,
  }
  appParamConfigStore.setConfig(finalAppParamConfig)
  return finalAppParamConfig
}

function initAdsense(paramAppConfig: ParamAppConfig) {
  if (paramAppConfig.clientType === ClientType.Web) {
    loadAdsenseScript()
    // preventAdsenseToResizeRootElement()
  }
}

export function startApp() {
  initSentry()

  const buildtimeConfig = new BuildtimeConfigBuilder().getBuildtimeConfig()
  const appParamConfig = getAppParamConfig()

  configureSentry(appParamConfig)

  ReactModal.setAppElement('#root')

  const { $crisp } = window as any
  $crisp.push(['do', 'chat:hide'])

  initAdsense(appParamConfig)

  const googleAnalyticsManager = new GoogleAnalyticsManager(appParamConfig, {
    [ClientType.Web]: 'UA-156055833-4',
    [ClientType.Android]: 'UA-156055833-5',
    [ClientType.IOS]: 'UA-156055833-6',
  })
  googleAnalyticsManager.initialize()

  const appDeps = new AppDepsBuilder(buildtimeConfig, appParamConfig).buildAppDeps()

  if (!appDeps.appUsageTracker.getFirstAppLaunchTimestamp()) {
    appDeps.appUsageTracker.setFirstAppLaunchTimestamp(Date.now())
  }

  const themeName = appDeps.uiThemeManager.getThemeName()
  document.body.className = `globalTheme${themeName}`
  window.ga('set', CustomDimensionName.ANDROID_CLIENT_VERSION_CODE, appParamConfig.androidClientVersionCode)
  window.ga('set', CustomDimensionName.IOS_CLIENT_VERSION, appParamConfig.iosClientVersion)
  window.ga('set', CustomDimensionName.NPM_PACKAGE_VERSION, buildtimeConfig.REACT_APP_NPM_PACKAGE_VERSION)
  window.ga('send', {
    hitType: 'event',
    eventCategory: 'uiThemeName',
    eventAction: themeName,
  })

  tryActivatePersistenStorage(appDeps)

  // Remove the original meta description tag hardcoded in the html,
  // since Google only shows the contents of the first tag
  const description = document.querySelector('meta[name="description"]')
  if (description) {
    description.remove()
  }

  ReactDOM.render(
    <Sentry.ErrorBoundary>
      <React.StrictMode>
        <BrandConfigContext.Provider value={createBcnBrandConfig(appDeps)}>
          <TransManager
            translationGroups={bcnTranslationGroups}
            allowToShowLocaleSelectionModal
          >
            <BrowserRouter>
              <ScrollMemory />
              <App><AppRoutes /></App>
            </BrowserRouter>
            <I18nModal />
          </TransManager>
        </BrandConfigContext.Provider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>,
    document.getElementById('root'),
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorkerRegistration.register({
    // Use the latest app version if it'available without
    // waiting the user to close the browser
    onUpdate: (registration: ServiceWorkerRegistration) => {
      const waitingServiceWorker = registration.waiting
      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', (event) => {
          const targetServiceWorker = event?.target as ServiceWorker
          if (targetServiceWorker.state === 'activated') {
            window.location.reload()
          }
        })
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
      }
    },
  })

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}
