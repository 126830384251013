import { UiThemeManager } from 'common/ui/services/ui-preferences-store/ui-theme-manager/UiThemeManager'
import StopMapStateStore from 'common/maps/services/stop-map-state-store/StopMapStateStore'
import FavoriteArrivalsStore from 'common/stops/services/favorite-arrivals-store/FavoriteArrivalsStore'
import SearchHistoryStore from 'common/search/services/search-history-store/SearchHistoryStore'
import UiPreferencesStore from 'common/ui/services/ui-preferences-store/UiPreferencesStore'
import LineApiClient from 'common/lines/services/line-api-client/LineApiClient'
import { LineListApiClient } from 'common/lines/services/line-list-api-client/LineListApiClient'
import { AdsManager } from 'common/ads/services/AdsManager'
import { AppUsageTracker } from 'common/core/services/app-usage-tracker/AppUsageTracker'
import { AdsUsagePersistor } from 'common/ads/services/AdsUsagePersistor'
import { StopListApiClient } from 'common/stops/services/stop-list-api-client/StopListApiClient'
import { ParamAppConfig } from 'common/core/models/param-app-config-models'
import { BuildtimeConfig } from 'common/core/models/buildtime-config-models'
import StopFavsStore from 'common/stops/services/stop-favs-store/StopFavsStore'
import VisitedStopsStore from 'common/stops/services/visited-stops-store/VisitedStopsStore'
import StopApiClient from 'common/stops/services/stop-api-client/StopApiClient'
import { I18nPersistor } from 'common/i18n/services/I18nPersistor'
import { FirebaseApp } from 'firebase/app'
import StopFavsService from 'common/stops/services/stop-favs-service/StopFavsService'
import { UserFirestoreClient } from 'common/stops/services/user-firestore-client/UserFirestoreClient'

export interface AppDeps {
  buildtimeConfig: BuildtimeConfig
  paramAppConfig: ParamAppConfig
  stopFavsStore: StopFavsStore
  stopApiClient: StopApiClient
  lineApiClient: LineApiClient
  stopMapStateStore: StopMapStateStore
  favoriteArrivalsStore: FavoriteArrivalsStore
  searchHistoryStore: SearchHistoryStore
  visitedStopsStore: VisitedStopsStore
  uiPreferencesStore: UiPreferencesStore
  uiThemeManager: UiThemeManager
  stopListApiClient: StopListApiClient
  lineListApiClient: LineListApiClient
  adsUsagePersistor: AdsUsagePersistor
  adsManager: AdsManager
  appUsageTracker: AppUsageTracker
  i18nPersistor: I18nPersistor
  firebaseApp: FirebaseApp
  stopFavsService: StopFavsService
  userFirestoreClient: UserFirestoreClient
}

export enum PageId {
  HomePage = 'HomePage',
  StopPage = 'StopPage',
  SearchPage = 'SearchPage',
  StopMapPage = 'StopMapPage',
  LinesPage = 'LinesPage',
  LinePage = 'LinePage',
  PostersPage = 'PostersPage',
  BusPosterPage = 'BusPosterPage',
  NocturnalBusPosterPage = 'NocturnalBusPosterPage',
  NewNetworkBusPosterPage = 'NewNetworkBusPosterPage',
  MetroPosterPage = 'MetroPosterPage',
  RodaliesPosterPage = 'RodaliesPosterPage',
  TrainsPosterPage = 'TrainsPosterPage',
}
