import React from 'react'
import {
  Switch,
  Route,
  useLocation,
  Redirect,
  Link,
  useParams,
} from 'react-router-dom'
import StopPage from 'common/stops/components/StopPage'
import HomePage from 'bcn/home/components/HomePage/HomePage'
import SearchPage from 'common/search/components/SearchPage/SearchPage'
import StopMapPage from 'common/maps/components/StopMapPage/StopMapPage'
import { PageId } from 'bcn/core/models/app-models'
import { RouteManager } from 'bcn/core/services/route-manager/RouteManager'
import AdminPage from 'common/core/components/AdminPage/AdminPage'
import SettingsPage from 'common/settings/components/SettingsPage/SettingsPage'
import LinePage from 'common/lines/components/LinePage/LinePage'
import { StopTypeNumCode } from 'bcn/stops/models/stop-models'
import LinesPage from 'common/lines/components/LinesPage/LinesPage'
import PosterImageView from 'common/posters/components/PosterImageView/PosterImageView'
import busPosterImgUrl from 'bcn/posters/images/plano_bus.jpg'
import newBusNetworkPosterImgUrl from 'bcn/posters/images/plano_bus_nueva_red.jpg'
import metroPosterImgUrl from 'bcn/posters/images/plano_metro.jpg'
import rodaliesPosterImgUrl from 'bcn/posters/images/plano_rodalies.jpg'
import PosterIframeView from 'common/posters/components/PosterIframeView/PosterIframeView'
import PosterPage from 'common/posters/components/PosterPage/PosterPage'
import PostersPage from 'bcn/posters/components/PostersPage/PostersPage'
import { useIntl } from 'react-intl'

const AppRoutes: React.FC = () => {
  const intl = useIntl()
  return (
    <Switch>
      <Route exact path={RouteManager.getAppDashboardUrlDef()}>
        <Redirect to={RouteManager.getStopsUrl()} />
      </Route>
      <Route exact path={RouteManager.getRootUrl()}>
        <Redirect to={RouteManager.getStopsUrl()} />
      </Route>
      <Route exact path={RouteManager.getStopsUrl()}>
        <HomePage />
      </Route>
      <Route exact path={RouteManager.getStopsSearchUrl()}>
        <SearchPage />
      </Route>
      <Route exact path={RouteManager.getStopsMapUrl()}>
        <StopMapPageWrapper />
      </Route>
      <Route exact path="/mapa-paradas">
        <Redirect to={RouteManager.getStopsMapUrl()} />
      </Route>
      <Route exact path="/buscar-parada">
        <Redirect to={RouteManager.getStopsSearchUrl()} />
      </Route>
      <Route path="/parada/:stopCode">
        <RedirectToStopPage />
      </Route>
      <Route exact path={RouteManager.getStopUrl('000')}>
        <AdminPage />
      </Route>
      <Route path={RouteManager.getStopUrlDef()}>
        <StopPage />
      </Route>

      <Route exact path={RouteManager.getLinesUrl()}>
        <LinesPage />
      </Route>
      <Route path={RouteManager.getLineUrlDef()}>
        <LinePage />
      </Route>

      <Route exact path={RouteManager.getPostersUrl()}>
        <PostersPage />
      </Route>
      <Route exact path={RouteManager.getBusPosterUrl()}>
        <PosterPage title={intl.formatMessage({ id: 'posters_buses' })} pageId={PageId.BusPosterPage}>
          <PosterImageView title={intl.formatMessage({ id: 'posters_buses' })} imageUrl={busPosterImgUrl} />
        </PosterPage>
      </Route>
      <Route exact path={RouteManager.getNocturnalBusPosterUrl()}>
        <PosterPage title={intl.formatMessage({ id: 'posters_nocturnal_buses' })} pageId={PageId.NocturnalBusPosterPage}>
          <PosterIframeView
            title={intl.formatMessage({ id: 'posters_nocturnal_buses' })}
            iframeUrl="https://www.easyzoom.com/embed/d389d6965c954edd9c2017f3fbc215cb"
          />
        </PosterPage>
      </Route>
      <Route exact path={RouteManager.getNewBusNetworkPosterUrl()}>
        <PosterPage title={intl.formatMessage({ id: 'posters_new_buses' })} pageId={PageId.NewNetworkBusPosterPage}>
          <PosterImageView title={intl.formatMessage({ id: 'posters_new_buses' })} imageUrl={newBusNetworkPosterImgUrl} />
        </PosterPage>
      </Route>
      <Route exact path={RouteManager.getMetroPosterUrl()}>
        <PosterPage title={intl.formatMessage({ id: 'posters_metro' })} pageId={PageId.MetroPosterPage}>
          <PosterImageView title={intl.formatMessage({ id: 'posters_metro' })} imageUrl={metroPosterImgUrl} />
        </PosterPage>
      </Route>
      <Route exact path={RouteManager.getRodaliesPosterUrl()}>
        <PosterPage title={intl.formatMessage({ id: 'posters_rodalies' })} pageId={PageId.RodaliesPosterPage}>
          <PosterImageView title={intl.formatMessage({ id: 'posters_rodalies' })} imageUrl={rodaliesPosterImgUrl} />
        </PosterPage>
      </Route>
      <Route exact path={RouteManager.getTrainsPosterUrl()}>
        <PosterPage title={intl.formatMessage({ id: 'posters_trains' })} pageId={PageId.TrainsPosterPage}>
          <PosterIframeView
            title={intl.formatMessage({ id: 'posters_trains' })}
            iframeUrl="https://www.easyzoom.com/embed/35bc5997710840a7b2ba2262012defc1"
          />
        </PosterPage>
      </Route>

      <Route exact path={RouteManager.getSettingsUrl()}>
        <SettingsPage />
      </Route>

      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  )
}

const RedirectToStopPage: React.FC = () => (
  <Redirect to={RouteManager.getStopUrl(useParams<{stopCode: string}>().stopCode)} />
)

const StopMapPageWrapper: React.FC = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const stopCode = queryParams.get(RouteManager.mapStopParamName) || undefined
  const lineNumber = queryParams.get(RouteManager.mapLineNumberParamName) || undefined
  const stopTypeNumCode = queryParams.get(
    RouteManager.mapStopTypeNumCodeParamName,
  ) as StopTypeNumCode || undefined
  return (
    <StopMapPage
      selectedStopCode={stopCode}
      lineNumber={lineNumber}
      stopTypeNumCode={stopTypeNumCode}
    />
  )
}

function NoMatch() {
  const { pathname, search } = useLocation()
  const path = `${pathname}${search}`
  return (
    <div>
      La página &quot;<code>{path}</code>&quot; no existe. <br />
      <Link to={RouteManager.getRootUrl()}>Ir a la página principal</Link>
    </div>
  )
}

export default AppRoutes
