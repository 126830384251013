import React from 'react'
import { PageId } from 'bcn/core/models/app-models'
import ActionBar from 'common/ui/components/ActionBar/ActionBar'
import PageMetadata from 'common/core/components/PageMetadata/PageMetadata'
import PageLayout from 'common/ui/components/PageLayout/PageLayout'
import Container from 'common/ui/components/Container/Container'
import PostersCatalog from 'common/posters/components/PostersCatalog/PostersCatalog'
import busPosterPreviewImg from 'bcn/posters/images/preview_plano_bus.jpg'
import newBusNetworkPosterPreviewImg from 'bcn/posters/images/preview_plano_bus_nueva_red.jpg'
import metroPosterPreviewImg from 'bcn/posters/images/preview_plano_metro.jpg'
import rodaliesPosterPreviewImg from 'bcn/posters/images/preview_plano_rodalies.jpg'
import trainsPosterPreviewImg from 'bcn/posters/images/preview_plano_red_ferroviaria.jpg'
import busNocturnalPosterImgUrl from 'bcn/posters/images/preview_plano_bus_nocturno.jpg'
import { RouteManager } from 'bcn/core/services/route-manager/RouteManager'
import { useIntl } from 'react-intl'

const PostersPage: React.FC = () => {
  const intl = useIntl()
  return (
    <PageLayout
      actionbar={<ActionBar title={intl.formatMessage({ id: 'posters_action_bar_title' })} />}
    >
      <PageMetadata pageId={PageId.PostersPage} />
      <Container>
        <PostersCatalog
          previews={[
            {
              text: intl.formatMessage({ id: 'posters_buses' }),
              previewUrl: busPosterPreviewImg,
              linkUrl: RouteManager.getBusPosterUrl(),
              isOffline: true,
            },
            {
              text: intl.formatMessage({ id: 'posters_new_buses' }),
              previewUrl: newBusNetworkPosterPreviewImg,
              linkUrl: RouteManager.getNewBusNetworkPosterUrl(),
              isOffline: true,
            },
            {
              text: intl.formatMessage({ id: 'posters_nocturnal_buses' }),
              previewUrl: busNocturnalPosterImgUrl,
              linkUrl: RouteManager.getNocturnalBusPosterUrl(),
              isOffline: false,
            },
            {
              text: intl.formatMessage({ id: 'posters_metro' }),
              previewUrl: metroPosterPreviewImg,
              linkUrl: RouteManager.getMetroPosterUrl(),
              isOffline: true,
            },
            {
              text: intl.formatMessage({ id: 'posters_rodalies' }),
              previewUrl: rodaliesPosterPreviewImg,
              linkUrl: RouteManager.getRodaliesPosterUrl(),
              isOffline: true,
            },
            {
              text: intl.formatMessage({ id: 'posters_trains' }),
              previewUrl: trainsPosterPreviewImg,
              linkUrl: RouteManager.getTrainsPosterUrl(),
              isOffline: false,
            },
          ]}
        />
      </Container>
    </PageLayout>
  )
}

export default PostersPage
