import React from 'react'
import { ReactComponent as TmbBusIcon } from 'bcn/stops/images/stop-bus.svg'
import { ReactComponent as MetroIcon } from 'bcn/stops/images/stop-metro.svg'
import { StopTypeNumCode } from 'bcn/stops/models/stop-models'

interface StopIconProps {
  stopTypeCode: string
}

const mapping: {[key in StopTypeNumCode]: () => JSX.Element } = {
  [StopTypeNumCode.Bus]: () => <TmbBusIcon width="32px" height="32px" />,
  [StopTypeNumCode.Metro]: () => <MetroIcon width="38px" height="32px" />,
}

const BcnBigStopIcon: React.FC<StopIconProps> = ({ stopTypeCode }) => {
  const iconRenderingFunction = mapping[stopTypeCode as StopTypeNumCode]
  if (iconRenderingFunction) {
    return iconRenderingFunction()
  }
  return <></>
}

export default BcnBigStopIcon
