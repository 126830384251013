import React from 'react'
import { ReactComponent as BusIcon } from 'bcn/stops/images/stop-bus.svg'
import { ReactComponent as MetroIcon } from 'bcn/stops/images/stop-metro.svg'
import { StopTypeNumCode } from 'bcn/stops/models/stop-models'

interface StopIconProps {
  stopTypeCode: string
}

const mapping: {[key in StopTypeNumCode]: React.FC<React.SVGProps<SVGSVGElement>> } = {
  [StopTypeNumCode.Bus]: BusIcon,
  [StopTypeNumCode.Metro]: MetroIcon,
}

const BcnStopIcon: React.FC<StopIconProps> = ({ stopTypeCode }) => {
  const IconComponent = mapping[stopTypeCode as StopTypeNumCode]
  if (IconComponent) {
    return <IconComponent />
  }
  return <></>
}

export default BcnStopIcon
