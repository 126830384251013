import { BcnAdsenseSlot } from 'bcn/ads/models/bcn-ads-models'
import { PageId } from 'bcn/core/models/app-models'
import { ClientType } from 'common/core/models/param-app-config-models'
import React from 'react'
import DisplayAd from 'common/ads/components/DisplayAd/DisplayAd'
import { DisplayAdWrapperType } from 'common/ads/components/ad-components-types'
import useBrandConfigContext from 'common/core/hooks/useBrandConfigContext'

const stopPageAdsMapping: {[clientType in ClientType]: BcnAdsenseSlot} = {
  [ClientType.Web]: BcnAdsenseSlot.WebStopPage,
  [ClientType.Android]: BcnAdsenseSlot.AndroidStopPage,
  [ClientType.IOS]: BcnAdsenseSlot.IosStopPage,
}

const linePageAdsMapping: {[clientType in ClientType]: BcnAdsenseSlot} = {
  [ClientType.Web]: BcnAdsenseSlot.WebLinePage,
  [ClientType.Android]: BcnAdsenseSlot.AndroidLinePage,
  [ClientType.IOS]: BcnAdsenseSlot.IosLinePage,
}

function getAdsenseSlot(pageId: PageId, clientType: ClientType): BcnAdsenseSlot | undefined {
  if (pageId === PageId.StopPage) {
    return stopPageAdsMapping[clientType]
  }
  if (pageId === PageId.LinePage) {
    return linePageAdsMapping[clientType]
  }
  return undefined
}

function renderAd(pageId: string, clientType: ClientType) {
  const adsenseSlot = getAdsenseSlot(pageId as PageId, clientType)
  if (!adsenseSlot) {
    return <></>
  }
  return <DisplayAd adsenseSlot={adsenseSlot} pageId={pageId} />
}

const BcnDisplayAdWrapper: DisplayAdWrapperType = ({ pageId }) => {
  const { paramAppConfig } = useBrandConfigContext().common.services

  if (paramAppConfig.clientType === ClientType.Web) {
    return renderAd(pageId, paramAppConfig.clientType)
  }
  return <></>
}

export default BcnDisplayAdWrapper
